<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Graduation Certificates</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label-for="search-session">
                                    <template v-slot:label>
                                        <span class="text-danger">*</span> Program 4 Session:
                                    </template>
                                    <!-- <template v-slot:description>
                                        <b-link @click="testme">[Load All]</b-link>
                                    </template> -->
                                    <b-input-group size="sm">
                                        <b-select id="search-session"
                                                  v-model="sessionFilter"
                                                  :options="sessionOptions"
                                                  :state="fieldState('sessionFilter')">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="sessionFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import download from '@/util/downloader';
import _ from 'underscore';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import {errorModalOptions, errorToastOptions} from "@/util/formatters";

@Component
export default class GraduationCertificates extends Vue {

    sessionFilter = null;

    showState = false;

    fieldState(field) {
        if (field.includes("Filter")) {
            return this.showState ? !!this[field] : undefined;
        } else {
            return this.showState ? !!this[field][0] : undefined;
        }
    }

    get sessionOptions() {
        const sessions = this.$store.getters['common/program4Sessions'];
        const options = _.map(sessions, s => {
            return {
                text: s.value,
                value: s.id
            };
        });
        return options;
    }

    clearFilters() {
        this.sessionFilter = null;
        this.showState = false;
    }

    async getCsv() {
        if (!this.isReady()) {
            return;
        }
        try {
            await download('POST', '/api/v1/export/graduation-certificates', JSON.stringify({
                workshopInstanceId: this.sessionFilter
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    isReady() {
        if (!this.sessionFilter) {
            this.showState = true;
            return false;
        }
        return true;
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Graduation Certificates', null, true));
        try {
            await this.$store.dispatch('common/loadProgram4Sessions');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }
}
</script>


<style scoped>
</style>
